<script setup>
  const { api } = useApi()
  const { getValue } = useAlchemy()
  const props = defineProps({
    element: {
      type: Object,
      default: () => ({}),
    },
  })

  const couponCode = getValue(props.element, "promotion")

  onMounted(() => {
    setTimeout(() => {
      api("/jsonapi/order/coupon_codes", {
        method: "POST",
        body: { data: { attributes: { couponCode } } },
      }).catch((err) => console.error(err))
    }, 2000)
  })
</script>
